<template>
  <div>
    <div
        style="width:100%; overflow: hidden;padding: 20px 0; display: flex; flex-direction: column; margin-top:0;background-color: #fff; position: sticky;top: 0px; z-index: 100">
      <div style="display: flex;">
        <el-input size="small" style="width: 120px;"
                  placeholder="请输入姓名"
                  v-model="input_name"
                  clearable
                  suffix-icon="el-icon-search"
        >
        </el-input>
        <el-input size="small" style="width: 160px; height: 30px; margin-left: 10px"
                  placeholder="请输入招聘批次"
                  v-model="input_turns"
                  clearable
                  suffix-icon="el-icon-search"
        >
        </el-input>
        <el-input size="small" style="width: 140px; height: 30px; margin-left: 10px"
                  placeholder="请输入机构"
                  v-model="input_organization"
                  clearable
                  suffix-icon="el-icon-search"
        >
        </el-input>
        <el-input size="small" style="width: 140px; height: 30px; margin-left: 10px"
                  placeholder="请输入岗位名称"
                  v-model="input_post_name"
                  clearable
                  suffix-icon="el-icon-search"
        >
        </el-input>
        <el-input size="small" style="width: 140px; height: 30px; margin-left: 10px"
                  placeholder="请输入岗位类型"
                  v-model="input_post_type"
                  clearable
                  suffix-icon="el-icon-search"
        >
        </el-input>
        <el-input size="small" style="width: 140px; height: 30px; margin-left: 10px"
                  placeholder="请输入状态"
                  v-model="input_process_status"
                  clearable
                  suffix-icon="el-icon-search"
        >
        </el-input>
        <el-button size="small" type="primary" style="margin-left: 10px; width: 80px" @click="handleSearch">搜索
        </el-button>
        <el-button size="small" type="warning" style="margin-left: 10px; width: 80px" @click="handleReset">重置
        </el-button>
      </div>

      <div style="display: flex; margin-top: 10px">
        <el-button size="small" type="primary" @click="handleAdd">
          <i class="el-icon-circle-plus-outline"></i>
          <span style="font-size: 12px; margin-right: 5px">新增</span>
        </el-button>
        <el-popconfirm
            title="确定继续执行批量删除吗？"
            icon="el-icon-info"
            icon-color="red"
            @confirm="handleDeleteBatch"
        >
          <el-button slot="reference" size="small" type="danger" style="font-size: 12px; margin: 0 5px">
            <i class="el-icon-remove-outline"></i>
            <span>批量删除</span>
          </el-button>
        </el-popconfirm>
        <el-button size="small" type="success" disabled>
          <i class="el-icon-download"></i>
          <span style="font-size: 12px; margin-right: 5px">导入</span>
        </el-button>
        <el-button size="small" type="success" disabled>
          <i class="el-icon-upload2"></i>
          <span style="font-size: 12px; margin-right: 5px">导出</span>
        </el-button>
      </div>
    </div>

    <div style="overflow: auto;">
      <el-table :data="tableData"
                style="margin-top: 5px"
                border
                fit
                highlight-current-row
                @selection-change="handleSelectionChange"
      >
        <af-table-column
            fixed
            type="selection"
        >
        </af-table-column>
        <af-table-column fixed prop="personId" width="140" label="人员编号">
        </af-table-column>
        <af-table-column fixed prop="personName" width="90" label="姓名">
        </af-table-column>
        <af-table-column prop="turnsName" width="150" label="所属批次">
        </af-table-column>
        <af-table-column prop="organizationName" width="100" label="机构">
        </af-table-column>
        <af-table-column prop="postName" width="190" label="岗位名称">
        </af-table-column>
        <af-table-column prop="postTypeName" width="120" label="岗位类型">
        </af-table-column>
<!--        <af-table-column prop="gradeFirst" width="120" label="笔试成绩" sortable>-->
<!--        </af-table-column>-->
<!--        <af-table-column prop="gradeSecond" width="120" label="面试成绩" sortable>-->
<!--        </af-table-column>-->
        <af-table-column prop="postLevelName" width="120" label="岗位层级">
        </af-table-column>

        <af-table-column prop="workDuration" width="120" label="工作年限">
        </af-table-column>
        <af-table-column prop="processStatusName" label="当前状态" :width="150">
          <template #default="{ row }">

            <el-tag v-if="row.processStatusName === '体检中'" type="danger">
              {{ row.processStatusName }}
            </el-tag>

            <el-tag v-else-if="row.processStatusName === '查档中'" type="warning">
              {{ row.processStatusName }}
            </el-tag>

            <el-tag v-else-if="row.processStatusName === '背调中'" type="warning">
              {{ row.processStatusName }}
            </el-tag>

            <el-tag v-else-if="row.processStatusName === '已放弃'" type="info">
              {{ row.processStatusName }}
            </el-tag>

            <el-tag v-else-if="row.processStatusName === '待入职'">
              {{ row.processStatusName }}
            </el-tag>
            <el-tag v-else-if="row.processStatusName === '未启用'" type="info">
              {{ row.processStatusName }}
            </el-tag>
            <el-tag v-else type="success">
              {{ row.processStatusName }}
            </el-tag>

          </template>
        </af-table-column>
        <af-table-column prop="enterDate" label="入职日期" :width="120" sortable>
          <template #default="{ row }">
            {{ row.enterDate ? row.enterDate.substring(0, 10) : "" }}
          </template>
        </af-table-column>
        <af-table-column prop="notes" label="备注">
        </af-table-column>
        <af-table-column
            fixed="right"
            label="操作"
            width="120">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope.row)" type="text" size="small">编辑</el-button>
            <el-popconfirm
                title="确定删除吗？"
                @confirm="handleDelete(scope.row.id)"
            >
              <el-button slot="reference" type="text" size="small" style="color: red; margin-left: 5px">删除
              </el-button>
            </el-popconfirm>
          </template>
        </af-table-column>
      </el-table>
    </div>


    <div style="padding: 10px 0">
      <el-pagination
          size="mini"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPageNum"
          :page-sizes="[5, 10, 20, 30]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog
        :title="form.person.id ? '编辑用户信息':'新增用户信息'"
        :visible.sync="dialogFormVisible"
        width="80%"
        :before-close="handleClose"
    >
      <el-form :rules="personRules" ref="personForm" :model="form.person" label-width="100px" size="small"
               style="display: flex;flex-direction: column; padding-right: 20px">
        <div class="infoRow">
          <el-form-item prop="personId" label="人员编号">
            <el-input
                v-model="form.person.personId"
                autocomplete="off"
                clearable
            ></el-input>
          </el-form-item>

          <el-form-item prop="personName" label="姓名">
            <el-input
                v-model="form.person.personName"
                autocomplete="off"
                clearable
            ></el-input>
          </el-form-item>

          <el-form-item prop="personGender" label="性别">
            <el-select v-model="form.person.personGender" placeholder="请选择性别" clearable>
              <el-option label="男" value="男"></el-option>
              <el-option label="女" value="女"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="personBornDate" label="出生日期">
            <el-date-picker
                type="date"
                placeholder="选择出生日期"
                v-model="form.person.personBornDate"
                value-format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
          </el-form-item>
        </div>

        <div class="infoRow">
          <el-form-item prop="organizationId" label="机构">
            <el-select v-model="form.person.organizationId" filterable clearable placeholder="请选择机构">
              <el-option
                  v-for="item in orgOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="postId" label="岗位名称">
            <el-select v-model="form.person.postId" filterable clearable placeholder="请选择岗位名称">
              <el-option
                  v-for="item in postOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="postLevelId" label="岗位层级">
            <el-select v-model="form.person.postLevelId" filterable clearable placeholder="请选择岗位层级">
              <el-option
                  v-for="item in postLevelOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="turnsId" label="招聘批次">
            <el-select v-model="form.person.turnsId" filterable clearable placeholder="请选择招聘批次">
              <el-option
                  v-for="item in turnsOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>

        <div class="infoRow">
<!--          <el-form-item prop="gradeFirst" label="笔试成绩">-->
<!--            <el-input-->
<!--                v-model="form.person.gradeFirst"-->
<!--                auto-complete="off"-->
<!--                clearable-->
<!--            ></el-input>-->
<!--          </el-form-item>-->

<!--          <el-form-item prop="gradeSecond" label="面试成绩">-->
<!--            <el-input-->
<!--                v-model="form.person.gradeSecond"-->
<!--                auto-complete="off"-->
<!--                clearable-->
<!--            ></el-input>-->
<!--          </el-form-item>-->

          <el-form-item prop="workDuration" label="工作年限">
            <el-input
                v-model="form.person.workDuration"
                auto-complete="off"
                clearable
            ></el-input>
          </el-form-item>

          <el-form-item prop="highestEducationId" label="最高学历">
            <el-select v-model="form.person.highestEducationId" placeholder="请选择最高学历" clearable>
              <el-option
                  v-for="item in highestEducationOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="processStatusId" label="当前状态">
            <el-select v-model="form.person.processStatusId" @change="processStatusChanged" placeholder="请选择当前状态"
                       clearable>
              <el-option
                  v-for="item in processStatusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="enterDate" label="入职时间" v-if="form.person.processStatusId === 1">
            <el-col>
              <el-date-picker
                  type="date"
                  placeholder="选择入职日期"
                  v-model="form.person.enterDate"
                  value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </el-col>
          </el-form-item>


        </div>

<!--        <div class="infoRow">-->
<!--          <el-form-item prop="processStatusId" label="当前状态">-->
<!--            <el-select v-model="form.person.processStatusId" @change="processStatusChanged" placeholder="请选择当前状态"-->
<!--                       clearable>-->
<!--              <el-option-->
<!--                  v-for="item in processStatusOptions"-->
<!--                  :key="item.value"-->
<!--                  :label="item.label"-->
<!--                  :value="item.value">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->

<!--          <el-form-item prop="enterDate" label="入职时间" v-if="form.person.processStatusId === 1">-->
<!--            <el-col>-->
<!--              <el-date-picker-->
<!--                  type="date"-->
<!--                  placeholder="选择入职日期"-->
<!--                  v-model="form.person.enterDate"-->
<!--                  value-format="yyyy-MM-dd HH:mm:ss">-->
<!--              </el-date-picker>-->
<!--            </el-col>-->
<!--          </el-form-item>-->
<!--        </div>-->

        <div class="infoRow">
          <el-form-item prop="predictEnterYear" label="预计入职年">
            <el-input
                type="textarea"
                autosize
                placeholder="请输入内容"
                v-model="form.person.predictEnterYear">
            </el-input>
          </el-form-item>

          <el-form-item prop="notes" label="备注">
            <el-input
                type="textarea"
                autosize
                placeholder="请输入内容"
                v-model="form.person.notes">
            </el-input>
          </el-form-item>


        </div>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelSave">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "SocialRecruit",
  data() {
    return {
      msg: 'hello , uncle young',
      tableData: [],
      total: 0,
      pageSize: 10,
      currentPageNum: 1,
      input_name: '',
      input_turns: '',
      input_apartment: '',
      input_organization: '',
      input_post_name: '',
      input_post_type: '',
      input_process_status: '',
      dialogFormVisible: false,
      multipleSelection: [],
      operateType: '',
      form: {
        person: {
          id: '',
          personId: '',
          personName: '',
          personGender: '',
          personBornDate: '',
          highestEducationId: '',
          turnsId: '',
          postId: '',
          gradeFirst: '',
          gradeSecond: '',
          postLevelId: '',
          apartmentId: '',
          organizationId: '',
          workDuration: '',
          processStatusId: '',
          enterDate: '',
          notes: '',
          predictEnterDate:'',
        },
        updateRecords: {
          updateUserId: '',
          updatePersonId: '',
          updateType: '',
          updateProcessStatusId: ''
        },

      },
      processStatusVo: {
        physicalTesting: '体检中',
        archivesChecking: '查档中',
        backgroundChecking: '背调中',
        workPending: '待入职',
        enterWork: '已入职',
        quit: '已放弃'
      },
      userInfo: {
        userRoleId: this.$store.state.currentUserInfo.userRoleId,
        userOrganizationId: this.$store.state.currentUserInfo.userOrganizationId,
      },
      orgOptions: [],
      postOptions: [],
      postLevelOptions: [],
      turnsOptions: [],
      highestEducationOptions: [],
      processStatusOptions: [],
      personRules: {
        personId: [
          {required: true, message: '人员编号不能为空', trigger: 'blur'},

          {pattern: /^[0-9]\d*$/, message: '请输入正确的人员编号', trigger: 'blur'}
        ],
        personName: [
          {required: true, message: '人员姓名不能为空', trigger: 'blur'}
        ],
        personGender: [
          {required: true, message: '人员性别不能为空', trigger: 'blur'}
        ],
        personBornDate: [
          {required: true, message: '人员出生日期不能为空', trigger: 'blur'}
        ],
        organizationId: [
          {required: true, message: '机构不能为空', trigger: 'blur'}
        ],
        postId: [
          {required: true, message: '岗位不能为空', trigger: 'blur'}
        ],
        postLevelId: [
          {required: true, message: '岗位层级不能为空', trigger: 'blur'}
        ],
        turnsId: [
          {required: true, message: '招聘批次不能为空', trigger: 'blur'}
        ],
        // gradeFirst: [
        //   {required: true, message: '笔试成绩不能为空', trigger: 'blur'},
        //   {type: 'number', message: '请输入正确的成绩', trigger: 'blur', transform: (value) => Number(value)}
        // ],
        // gradeSecond: [
        //   {required: true, message: '面试成绩不能为空', trigger: 'blur'},
        //   {type: 'number', message: '请输入正确的成绩', trigger: 'blur', transform: (value) => Number(value)}
        //
        // ],
        workDuration: [
          {type: 'number', message: '请输入正确的工作年限', trigger: 'blur', transform: (value) => Number(value)}
        ],
        highestEducationId: [
          {required: true, message: '最高学历不能为空', trigger: 'blur'}
        ],
        processStatusId: [
          {required: true, message: '流程状态不能为空', trigger: 'blur'}
        ],
        enterDate: [
          {required: true, message: '入职日期不能为空', trigger: 'blur'}
        ],
        notes: [],
        predictEnterYear: [
          {required: true, message: '预计入职年份必填', trigger: 'blur'},
          {type: 'number', message: '请正确填写预计入职年份', trigger: 'blur', transform: (value) => Number(value)}
        ],
      }
    }
  },
  methods: {
    processStatusChanged(val) {
      if (val !== 1) {
        this.form.person.enterDate = ''
      }
    },
    handleAdd() {
      this.dialogFormVisible = true
    },
    handleSearch() {
      this.currentPageNum = 1
      this.loadData()
    },
    handleReset() {
      this.input_name = ''
      this.input_turns = ''
      this.input_organization = ''
      this.input_post_name = ''
      this.input_post_type = ''
      this.input_process_status = ''
      this.currentPageNum = 1
      this.loadData()
    },
    handleCurrentChange(pageNum) {
      // console.log(pageNum)
      this.currentPageNum = pageNum
      this.loadData()
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.loadData()
    },
    handleEdit(row) {
      // 进行一次深拷贝，将row数据拷贝至form.person，防止在表单中更改数据时，引起表格中数据的临时同步更改
      this.form.person = {...row}
      this.dialogFormVisible = true
    },
    handleDelete(id) {
      this.request.delete("/person/" + id).then(res => {
        if (res) {
          this.$message.success("删除成功")
          this.loadData()
        } else {
          this.$message.error("删除失败")
        }
      })
    },
    handleSelectionChange(val) {
      // console.log(val)
      this.multipleSelection = val
    },
    handleDeleteBatch() {
      let ids = this.multipleSelection.map(v => v.id)
      console.log("ids", ids)
      if (ids.length == 0) {
        this.$message.warning("没有选中的数据")
      } else {
        this.request.post("/person/deleteBatch", ids).then(res => {
          if (res) {
            this.$message.success("批量删除成功")
            this.loadData()
          } else {
            this.$message.error("批量删除失败")
          }
        })
      }

    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
            this.dialogFormVisible = false
            this.clearPersonInfoForm()
          })
          .catch(_ => {
          });
    },
    loadData() {
      this.request.get("/person/queryPage", {
        params: {
          pageNum: this.currentPageNum,
          pageSize: this.pageSize,
          personName: this.input_name,
          turns: this.input_turns,
          organizationName: this.input_organization,
          postName: this.input_post_name,
          postType: this.input_post_type,
          processStatus: this.input_process_status,
          userRoleId: JSON.parse(localStorage.getItem("user")).roleId,
          userOrganizationId: JSON.parse(localStorage.getItem("user")).organizationId,
        }
      }).then(res => {
        this.tableData = res.records
        this.total = res.total
      })
    },
    clearPersonInfoForm() {
      this.form.person.id = '',
          this.form.person.personId = '',
          this.form.person.personName = '',
          this.form.person.personGender = '',
          this.form.person.personBornDate = '',
          this.form.person.highestEducationId = '',
          this.form.person.turnsId = '',
          this.form.person.postId = '',
          this.form.person.gradeFirst = '',
          this.form.person.gradeSecond = '',
          this.form.person.postLevelId = '',
          this.form.person.apartmentId = '',
          this.form.person.organizationId = '',
          this.form.person.workDuration = '',
          this.form.person.processStatusId = '',
          this.form.person.enterDate = '',
          this.form.person.notes = '',
          this.form.person.predictEnterYear = ''
    },
    save() {
      let opType = ""
      if (this.form.person.id === "") {
        opType = "新增"
        this.form.updateRecords.updateUserId = JSON.parse(localStorage.getItem("user")).userId
        this.form.updateRecords.updatePersonId = this.form.person.personId
        this.form.updateRecords.updateType = 0
        this.form.updateRecords.updateProcessStatusId = this.form.person.processStatusId
      } else {
        opType = "编辑"
        this.form.updateRecords.updateUserId = JSON.parse(localStorage.getItem("user")).userId
        this.form.updateRecords.updatePersonId = this.form.person.personId
        this.form.updateRecords.updateType = 1
        this.form.updateRecords.updateProcessStatusId = this.form.person.processStatusId
      }
      this.$refs['personForm'].validate((valid) => {
        if (valid) {
          this.request.post("/person", this.form).then(res => {
            if (res) {
              this.$message.success(opType + "成功")
              this.loadData()
            } else {
              this.$message.error(opType + "失败")
            }
          })
          this.dialogFormVisible = false
          this.clearPersonInfoForm()
        }
      })


    },
    cancelSave() {
      this.dialogFormVisible = false
      // this.clearPersonInfoForm()
    },
    getOrgList() {
      this.request.get("/organization", {}).then(res => {
        if (res) {
          this.orgOptions = res
        }
      })
    },
    getPostList() {
      this.request.get("/postList/selectList", {}).then(res => {
        if (res) {
          this.postOptions = res
        }
      })
    },
    getPostLevelList() {
      this.request.get("/postLevel/selectList", {}).then(res => {
        if (res) {
          this.postLevelOptions = res
        }
      })
    },
    getTurnsList() {
      this.request.get("/turns/selectList", {}).then(res => {
        if (res) {
          this.turnsOptions = res
        }
      })
    },
    getHighestEducationList() {
      this.request.get("/education/selectList", {}).then(res => {
        if (res) {
          this.highestEducationOptions = res
        }
      })
    },
    getProcessStatusList() {
      this.request.get("/processStatus/selectList", {}).then(res => {
        if (res) {
          this.processStatusOptions = res
        }
      })
    },
  },
  created() {
    // 请求分页查询数据
    this.loadData()
  },
  mounted() {
    this.getOrgList()
    this.getPostList()
    this.getPostLevelList()
    this.getTurnsList()
    this.getHighestEducationList()
    this.getProcessStatusList()
  },
  watch: {
    dialogFormVisible(newVal, oldVal) {
      if (!newVal) {
        this.clearPersonInfoForm()
      }
    }

  }
}
</script>

<style scoped>
.infoRow {
  display: flex;
  /*justify-content: space-between;*/
  margin: 10px 0 10px 0;
}

.el-form-item {
  width: 25%;
}

/deep/ .el-form-item__label {
  color: #221f51;
  /*font-weight: bold;*/
  font-size: 14px
}


.el-select {
  width: 100%;
}
/*日期选择组件宽度自定义*/
/deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 98%;
}
</style>